<template>
  <div class="">
    
    <div class="">
      <div class="">
        <div class="">
          <div v-if="isLoading" class="loader  d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center" >
              <b-spinner small style="color: #4d1b7e"></b-spinner>
              <span class="ml-2">Loading Segments</span>
            </div>
          </div>
          <!-- -->
          <b-skeleton-table
            v-if="isLoadingFirstTime"
            :rows="5"
            :columns="4"
            :table-props="{ bordered: true }"
          ></b-skeleton-table>
          <b-table
            row-class="custom-row-class"
            v-else-if="items.length > 0"
            hover
             :responsive="true"
            head-class="text-center"
            class="bg-table-heading bg-table-heading"
            :fields="fields"
            :items="items"
            show-empty
          >
            <template #cell(name)="data">
              <div class="d-flex">
                <div class="list-col">
                  {{ data.item.name }}
                </div>
                <!-- <div class="ml-2 segment-text">
                  Segment
                </div> -->
              </div>
              <div class="q-collapse" v-if="data.item.type=='questions_logic'">
                <div class="rounded py-2 px-4">
                  <div class="" v-b-toggle="'segment' + data.item.created_at">
                    <span class="collapse-title d-flex">
                      <span class="when-opened">
                        <i class="fa-solid fa-angle-down"></i>
                      </span>
                      <span class="when-closed">
                        <i class="fa-solid fa-angle-right"></i>
                      </span>
                      <span class="user-name ml-2 mb-2">View definition</span>
                    </span>
                  </div>
                  <b-collapse :id="'segment' + data.item.created_at">
                    <ul
                      class="definitions stanzaContainer"
                      style="list-style-type: none"
                    >
                      <li
                        v-for="(stanza, index) in data.item.stanzas"
                        :key="index"
                      >
                        <ul class="criteriaContainer">
                          <b-card>
                            <li
                              v-for="criteria in stanza.criteria"
                              :key="criteria.id"
                            >
                              <div class="pb-2">
                                Answer for the
                                <span style="font-weight: 500"
                                  >"{{ criteria.question }}"</span
                                >
                                was
                                <span style="font-weight: 500"
                                  >"{{ criteria.option }}"</span
                                >
                              </div>
                              <p class="CriteriaRelationContent collapse-title">OR</p>
                            </li>
                          </b-card>
                        </ul>

                        <p class="StanzaRelationContent collapse-title mt-2">AND</p>
                      </li>
                    </ul>
                  </b-collapse>
                </div>
              </div>
            </template>
            <template #cell(created_at)="data">
              {{ formattedDate(data.item.created_at) }}
            </template>
            <template #cell(actions)="data">
              <b-dropdown dropright class="" id="collection-detail">
                <template slot="button-content">
                  <div class="p-2">
                    <i class="text-dark fa-solid fa-ellipsis-vertical"></i>
                  </div>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'SegmentsLeads',
                    params: { name: data.item.name, id: data.item.id },
                  }"
                  class="topbarLink d-flex align-items-center"
                >
                  <!-- <router-link class="w-100" style="text-decoration: none; color:inherit" >View</router-link> -->
                  View
                </b-dropdown-item>
                <b-dropdown-item
                  class="topbarLink d-flex align-items-center"
                 :to="'/manage/emails/segments/update/'+encodeId(data.item.id)"
                >
                  Edit</b-dropdown-item
                >
                <b-dropdown-item
                 
                  class="topbarLink d-flex align-items-center"
                  @click="()=>{deleteSegmentId = data.item.id;$refs.segmentDelete.open()}"
                >
                  Delete</b-dropdown-item
                >
              </b-dropdown>
            </template>
          </b-table>
          <template v-else>

<div class="d-flex justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No segment found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
        </div>
      </div>
    </div>
<DeleteModal   ref="segmentDelete"
id="segmentsBulkDelete" title="Delete Segment" item="segment" :isLoading="deleteSegmentLoading" @click="Deletesegment()"></DeleteModal>
    <FooterContent >
<div class="d-flex align-items-center justify-content-end">
  
  <Button @click="goToCreateSegments"> + Create Segment </Button>
</div>
</FooterContent>
  </div>
</template>
<script>

import DeleteModal from "../../modal/DeleteModal.vue";
import axios from "axios";
import FooterContent from '../../Layout/FooterContent.vue';
import Button from "../../Layout/Button.vue";
import EmptyTable from "../../Layout/EmptyTable.vue";
// import Button from "../Layout/Button.vue";
export default {
  components: {
    Button,
    DeleteModal,
    FooterContent,
    EmptyTable
    // Button
  },

  computed: {},
  data() {
    return {
      deleteSegmentLoading: false,
      deleteSegmentId: "",
      isLoading: false,

      isLoadingFirstTime: true,
      fields: [
        {
          key: "name",
          label: "List",
          tdClass: "data-Table cursor-pointer",
          thStyle: { width: "650px" },
          thClass: "bg-table-heading",
        },
        {
          key: "members",
          label: "Members",
          tdClass: "data-Table ",

          thClass: "bg-table-heading  ",
        },
        {
          key: "created_at",
          label: "Created",
          tdClass: "data-Table cursor-pointer  ",
          thClass: "bg-table-heading ",
        },
        {
          key: "actions",
          label: "",
          tdClass: "data-Table cursor-pointer d-flex justify-content-end",
          thClass: "bg-table-heading  ",
        },
      ],
      items: [{}],
    };
  },
  methods: {
    encodeId(id){
      return btoa(id.toString()+'-quizell.com')
    },
    goToCreateSegments() {
      this.$router.push({ name: 'CreateSegments' });
    },
    formattedDate(timestamp) {
      const date = new Date(timestamp);

      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const year = String(date.getUTCFullYear());

      return `${day}-${month}-${year}`;
    },

    async Deletesegment() {
      this.deleteSegmentLoading = true;
      await axios
        .delete(`/api/v1/segments/` + this.deleteSegmentId)
        .then(() => {
          this.$toasted.show("Segments are successfully deleted", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.deleteSegmentId = "";
          this.$refs["segmentDelete"].close();
          this.getSegments();
        })
        .catch((error) => {
          if (error) {
            this.$toasted.show("Error occured", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        });
      this.deleteSegmentLoading = false;
    },
    async getSegments() {
      this.isLoading = true;
      await axios
        .get("/api/v1/segments")
        .then((response) => {
          if (response.data.data) {
            this.items = response.data.data.segments;
          }
        })
        .catch((e) => console.log(e))
        .finally(() => (this.isLoading = false));
      this.isLoadingFirstTime = false;
    },
  },
  async mounted() {
    await this.getSegments();
  },
};
</script>

<style scoped>
.search-text:focus-visible {
  outline: none;
}
.search-text:active + .fa-search {
  color: #4d1b7e;
}
.tag {
  background: #bfbfc0;
  border-radius: 20px;
  border: 1px dashed;
  font-size: 14px;
}
.icon {
  color: #949495;
  transition: 0.5s ease all;
}
.icon:hover {
  background: rgb(77 27 126 / 20%);
  color: #4d1b7e;
}
.search {
  border-radius: 6px;
  border: 0.8px solid #b3afb6;
}
.search:focus-within {
  color: #495057;
  background-color: #fff;
  /* border-color: #4d1b7e; */
  outline: none;
  box-shadow: 0 0 0 0.1rem rgba(77, 27, 126, 0.25);
}
.cursor-pointer {
  cursor: pointer;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.floatingDiv {
  position: fixed;
  top: auto;
  bottom: 20px;
  width: 90%;
}

.floatingDiv .InnerDiv {
  z-index: 999 !important;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0rem 0.125rem 0.25rem #1f21241a, 0rem 0.0625rem 0.375rem #1f21240d,
    0rem 0.25rem 1.125rem -0.125rem #1f212414,
    0rem 0.75rem 1.125rem -0.125rem #1f212426;
  padding: 10px;
}
.deleteProduct {
  background: rgba(192, 21, 21, 0.2);
  border-radius: 6px;
  color: #c01515;
}
.download-btn {
  border-radius: 4px;
  background: rgba(77, 27, 126, 0.2);
}
.download-title {
  color: #1f1f28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.loader {
  min-height: 40px;
}
.bg-table-heading bg-table-heading >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.bg-table-heading bg-table-heading >>> thead tr {
  border-radius: 10px;
  cursor: pointer;
}
.bg-table-heading bg-table-heading >>> thead th {
  border: none;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.dropdown >>> .btn-danger {
  color: #4d1b7e;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.dropdown >>> .btn-danger:active {
  color: #3f1768;
  background: rgba(77, 27, 126, 0.12);
  font-weight: 600;
  border: none;
}
.date >>> #Starting,
.date >>> #ending {
  color: #3f1768;
}
.collapse-title {
  color: #0d7de4;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-row-class:hover {
  cursor: pointer; /* Change this to the desired cursor style */
}

.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}
.list-col {
  color: #18191c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: capitalize;
}
.definitions {
  color: #18191c;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}

.criteriaContainer {
  list-style-type: none;
}
.criteriaContainer .CriteriaRelationContent,
.stanzaContainer .StanzaRelationContent {
  color: #ffa201;
}
.criteriaContainer li:last-child .CriteriaRelationContent  ,  .stanzaContainer li:last-child .StanzaRelationContent{
 display: none;
}

</style>
